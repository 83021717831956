<template>
  <div>
    <div class="checkWrapper">
      <b-icon icon="x" class="checkIcon" />
    </div>
    <h1>Fehler</h1>
    <p>{{errorMsg || 'Es ist ein Fehler aufgetreten'}}</p>
    <router-link to="/">
      <small>
        <b-icon icon="chevron-left" />
        Zurück zum Start
      </small>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {
      errorMsg: this.$route.params.error,
    };
  },
};
</script>

<style scoped>
  .checkWrapper {
    left: calc(50% - 2rem);
    position: absolute;
    top: -2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #dd4d4d;
    padding: 1rem;
  }
  .checkIcon {
    width: 2rem;
    height: 2rem;
    color: white;
  }
</style>
